import Image from "next/image";
import Link from "next/link";

import { generateMerchantSlug } from "helpers/merchant";
import MerchantSliderItemPropTypes from "types/MerchantSliderItem.propTypes";

import Rating from "components/ui/rating/Rating";
import Pill from "components/ui/pill/Pill";

import {
    Container,
    Content,
    ImageContainer,
    Title,
    RatingAndLabel,
    Location,
    Arrow
} from "./Card.styled";

function Card({ slug, title, image, score, themes, address, ...props }) {
    return (
        <Container>
            <Link
                href={generateMerchantSlug(
                    address?.provinceCode,
                    address?.city,
                    slug
                )}
                passHref
                prefetch={false}
            >
                <a>
                    <ImageContainer>
                        {image && (
                            <Image
                                src={image.url}
                                alt={image.alt}
                                quality={75}
                                layout="fill"
                                objectFit="cover"
                            />
                        )}
                    </ImageContainer>
                    <Content>
                        <Title aria-label={`Resort: ${title}`}>{title}</Title>
                        {(score > 0 || props?.private) && (
                            <RatingAndLabel>
                                {score > 0 && <Rating score={score} />}
                                {props?.private && <Pill>privé sauna</Pill>}
                            </RatingAndLabel>
                        )}
                        {address?.city && (
                            <Location aria-label={`Locatie: ${address.city}`}>
                                {address.city}
                            </Location>
                        )}
                        <Arrow
                            size={18}
                            fill="var(--color-primary)"
                            aria-hidden="true"
                        />
                    </Content>
                </a>
            </Link>
        </Container>
    );
}

Card.propTypes = MerchantSliderItemPropTypes;

Card.defaultProps = {
    score: 0,
    private: true,
    themes: []
};

export default Card;
