import styled from "styled-components";

export const Container = styled.div`
    padding: 2px 10px 3px;

    color: var(--color-white);
    font-weight: var(--font-weight-semi-bold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    word-break: keep-all;

    background-color: var(--color-purple-500);
    border-radius: 5px;
`;
