import styled from "styled-components";

import media from "constants/media";

import { skeleton } from "components/ui/skeleton/Skeleton";

export const Image = styled.div`
    height: 100%;

    ${skeleton()}
`;

export const Title = styled.div`
    display: block;
    width: 100%;
    height: 18px;
    margin-bottom: 10px;

    border-radius: var(--border-radius-default);

    ${media.mdUp} {
        height: 23px;
    }

    ${skeleton()}
`;

export const Theme = styled.div`
    width: 50%;
    height: 20px;
    margin-bottom: 10px;

    border-radius: var(--border-radius-default);

    ${media.mdUp} {
        margin-bottom: 30px;
    }

    ${skeleton()}
`;

export const Location = styled.div`
    width: 33%;
    height: 20px;

    border-radius: var(--border-radius-default);

    ${skeleton()}
`;
