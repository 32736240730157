import PropTypes from "prop-types";

function IconArrowRight({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M37.6469481,47.3961079 C36.8219665,48.2205481 36.8219665,49.5572297 37.6469481,50.3816699 C38.4719297,51.20611 39.8094891,51.20611 40.6344707,50.3816699 L59.0282119,32 L40.6344707,13.6183301 C39.8094891,12.79389 38.4719297,12.79389 37.6469481,13.6183301 C36.8219665,14.4427703 36.8219665,15.7794519 37.6469481,16.6038921 L53.0531667,32 L37.6469481,47.3961079 Z M6.11111123,30.0000001 C4.94479392,29.9996173 3.99961728,30.9447939 4.00000012,32.1111112 C4.00038295,33.2774285 4.9461803,34.2232259 6.11249761,34.2236087 L54.9198571,34.2321444 L54.9198571,30.0071495 L6.11111123,30.0000001 Z" />
        </svg>
    );
}

IconArrowRight.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconArrowRight.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconArrowRight;
