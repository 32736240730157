import PropTypes from "prop-types";
import Carousel, {
    NextButton,
    PagingDots,
    PreviousButton
} from "nuka-carousel";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, Chevron, LeftFade, RightFade } from "./Slider.styled";

function shouldShowControls(navigationMobile, navigationDesktop, mobileView) {
    return !!(
        (navigationMobile && mobileView) ||
        (navigationDesktop && !mobileView)
    );
}

function Slider({
    className,
    items,
    slidesToShow,
    cellSpacing,
    navigationMobile,
    navigationDesktop,
    pagination,
    overflowVisible,
    infinite,
    fadeEdges
}) {
    const { mobileView } = useMediaQueryContext();
    const showControls = shouldShowControls(
        navigationMobile,
        navigationDesktop,
        mobileView
    );

    return (
        <Container
            navigationMobile={navigationMobile}
            navigationDesktop={navigationDesktop}
            fadeEdges={fadeEdges}
            className={className}
        >
            <Carousel
                cellSpacing={cellSpacing}
                cellAlign="left"
                framePadding={mobileView ? "0 0 70px" : "0 0 100px"}
                defaultControlsConfig={{
                    pagingDotsContainerClassName:
                        "slider-dots-container-custom",
                    pagingDotsClassName: "slider-dots-dot-custom",
                    // Set non-existing value to remove inline styles
                    prevButtonStyle: {
                        padding: "unset-inline-style",
                        background: "unset-inline-style",
                        border: "unset-inline-style",
                        opacity: "unset-inline-style"
                    },
                    prevButtonClassName: "slider-nav-btn prev-btn",
                    prevButtonText: (
                        <Chevron
                            type="left"
                            fill="var(--color-primary)"
                            width={24}
                            height={24}
                            aria-label="Vorige item"
                        />
                    ),
                    // Set non-existing value to remove inline styles
                    nextButtonStyle: {
                        padding: "unset-inline-style",
                        background: "unset-inline-style",
                        border: "unset-inline-style",
                        opacity: "unset-inline-style"
                    },
                    nextButtonClassName: "slider-nav-btn next-btn",
                    nextButtonText: (
                        <Chevron
                            type="right"
                            fill="var(--color-primary)"
                            width={24}
                            height={24}
                            aria-label="Volgende item"
                        />
                    )
                }}
                autoGenerateStyleTag={false}
                edgeEasing="easeExpOut"
                heightMode="max"
                slidesToShow={
                    items?.length >= slidesToShow ? slidesToShow : items?.length
                }
                slidesToScroll="auto"
                wrapAround={infinite}
                width="100%"
                vertical={false}
                frameOverflow={overflowVisible ? "visible" : "hidden"}
                getControlsContainerStyles={key => {
                    switch (key) {
                        case "BottomCenter":
                            return {
                                bottom: mobileView ? "-55px" : "-70px"
                            };
                        default:
                            return "";
                    }
                }}
                renderCenterLeftControls={null}
                renderBottomCenterControls={props => {
                    if (slidesToShow >= items.length || !pagination) {
                        return null;
                    }

                    return <PagingDots {...props} />;
                }}
                renderBottomLeftControls={props =>
                    showControls && <PreviousButton {...props} />
                }
                renderCenterRightControls={null}
                renderBottomRightControls={props =>
                    showControls && <NextButton {...props} />
                }
                renderTopLeftControls={() =>
                    fadeEdges && !mobileView && <LeftFade />
                }
                renderTopRightControls={() =>
                    fadeEdges && !mobileView && <RightFade />
                }
            >
                {items}
            </Carousel>
        </Container>
    );
}

Slider.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    slidesToShow: PropTypes.number,
    cellSpacing: PropTypes.number,
    navigationMobile: PropTypes.bool,
    navigationDesktop: PropTypes.bool,
    pagination: PropTypes.bool,
    overflowVisible: PropTypes.bool,
    infinite: PropTypes.bool,
    fadeEdges: PropTypes.bool
};

Slider.defaultProps = {
    className: "",
    items: [],
    slidesToShow: 1.2,
    cellSpacing: 20,
    navigationMobile: true,
    navigationDesktop: true,
    pagination: true,
    overflowVisible: false,
    infinite: false,
    fadeEdges: false
};

export default Slider;
