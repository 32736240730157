import styled from "styled-components";

import media from "constants/media";

export const MerchantSliderStyled = styled.div`
    width: 100%;
    margin: 30px 0 0;
    padding: 0;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const Title = styled.h2`
    margin: 0 0 20px;

    font-size: 26px;
    line-height: 35px;

    ${media.mdUp} {
        margin: 0 0 30px;

        font-size: 30px;
    }
`;
