import styled from "styled-components";

import media from "constants/media";

import IconArrowRight from "components/ui/icons/IconArrowRight";

export const Arrow = styled(IconArrowRight)`
    position: absolute;
    right: 20px;
    bottom: 20px;

    transition: all var(--animation-duration-default) ease;

    ${media.mdUp} {
        width: 24px;
        height: 24px;
    }
`;

export const Title = styled.h3`
    margin: 0;
    padding: 0;
    overflow: hidden;

    color: var(--color-black);
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all var(--animation-duration-default) ease;

    ${media.mdUp} {
        font-size: 16px;
        line-height: 25px;
    }
`;

export const Container = styled.article`
    display: block;
    width: 100%;
    overflow: hidden;

    background-color: var(--color-white);
    border-radius: var(--border-radius-default);

    transition: all var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        :hover,
        :focus {
            transform: scale(1.02);

            ${Title} {
                color: var(--color-primary);
            }

            ${Arrow} {
                transform: translateX(5px);

                fill: var(--color-blue-400);
            }
        }
    }
`;

export const Content = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    padding: 20px;
`;

export const ImageContainer = styled.div`
    position: relative;

    width: 100%;

    background-color: var(--color-blue-700);
    aspect-ratio: 2.1;
`;

export const RatingAndLabel = styled.p`
    display: flex;
    gap: 0 20px;
    align-items: center;
    order: -1;
    margin: 0 0 5px;
`;

export const Location = styled.p`
    margin: 0;
    overflow: hidden;

    color: var(--color-gray-400);
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
