import PropTypes from "prop-types";

function IconStar({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill="none"
            stroke={fill}
            strokeWidth="4.4715"
            {...rest}
        >
            <path d="m35.5 51.9c-2.1-1.3-4.8-1.3-6.9 0l-11.6 7c-1.1.6-2.4.3-3.1-.8-.3-.5-.4-1.1-.3-1.7l3.1-13.2c.6-2.4-.3-5-2.1-6.6l-10.4-8.9c-.9-.8-1-2.2-.2-3.2.4-.4.9-.7 1.5-.8l13.5-1c2.5-.2 4.6-1.8 5.6-4.1l5.3-12.5c.5-1.1 1.8-1.7 2.9-1.2.5.2 1 .7 1.2 1.2l5.3 12.5c1 2.3 3.1 3.9 5.6 4.1l13.5 1.1c1.2.1 2.1 1.2 2 2.4-.1.6-.3 1.1-.8 1.5l-10.3 8.9c-1.9 1.6-2.7 4.2-2.1 6.6l3.1 13.2c.3 1.2-.5 2.4-1.7 2.7-.6.1-1.2 0-1.7-.3z" />
        </svg>
    );
}

IconStar.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconStar.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconStar;
