import styled, { css } from "styled-components";

import media from "constants/media";

import IconChevronRight from "components/ui/icons/IconChevronRight";

export const Chevron = styled(IconChevronRight)`
    transform: rotate(${({ type }) => (type === "left" ? -180 : 0)}deg);
`;

const Button = css`
    .slider-nav-btn {
        position: absolute;
        bottom: -75px;

        padding: 14px;

        background: var(--color-gray-700);
        border: 2px solid var(--color-blue-600);

        border-radius: 100%;
        cursor: pointer;

        transition: all var(--animation-duration-default) ease-in-out;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }

        :not(:disabled):hover,
        :not(:disabled):focus {
            border-color: var(--color-primary);

            ${Chevron} {
                fill: var(--color-primary);
            }
        }
    }

    .next-btn {
        right: 0;
    }

    .prev-btn {
        left: 0;
    }
`;

const Pagination = css`
    .paging-dot {
        width: 10px;
        height: 10px;
        margin: 0 4px;

        transition: all var(--animation-duration-default) ease;

        fill: var(--color-primary);

        circle {
            cx: 5;
            cy: 5;
            r: 5;
        }
    }

    .paging-item.active .paging-dot {
        margin: 0 5px;

        transform: scale(1.6);
    }
`;

const Slider = css`
    .slider,
    .slider-slide {
        outline: none;
    }
`;

export const Container = styled.div`
    width: 100%;

    ${({ fadeEdges }) =>
        fadeEdges &&
        css`
            ${media.mdUp} {
                width: calc(100% + 300px);
                padding: 20px 200px 0 100px;
                margin: -20px -200px 0 -100px;
                overflow: hidden;

                .slider-control-topleft,
                .slider-control-topright {
                    width: 0;
                    height: 100%;
                }
            }
        `}

    ${Slider}

    ${Pagination}

    ${Button}
`;

const Fade = css`
    position: absolute;
    top: -20px;

    height: calc(100% + 80px);
`;

export const LeftFade = styled.div`
    left: -100px;

    width: 100px;

    background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 20%
    );

    ${Fade}
`;

export const RightFade = styled.div`
    right: -250px;

    width: 300px;

    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 75%
    );

    ${Fade}
`;
