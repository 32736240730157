import PropTypes from "prop-types";
import { Container } from "./Pill.styled";

function Pill({ children }) {
    return <Container>{children}</Container>;
}

Pill.defaultProps = {
    children: ""
};

Pill.propTypes = {
    children: PropTypes.node
};

export default Pill;
