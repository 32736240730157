import PropTypes from "prop-types";

import IconStar from "components/ui/icons/IconStar";

import { Score } from "./Rating.styled";

function Rating({ score, className }) {
    if (Number.isNaN(score) || score === 0) {
        return null;
    }

    const rating = new Intl.NumberFormat("nl-NL", {
        notation: "compact",
        minimumFractionDigits: 1
    }).format(score);

    return (
        <Score
            aria-label={`Beoordeeld met een ${rating}`}
            className={className}
        >
            <IconStar size={16} />
            {rating}
        </Score>
    );
}

Rating.propTypes = {
    score: PropTypes.number,
    className: PropTypes.string
};

Rating.defaultProps = {
    score: 0,
    className: ""
};

export default Rating;
