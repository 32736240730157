import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { useInView } from "react-intersection-observer";

import { MerchantSliderItemApiPropTypes } from "types/MerchantSliderItem.propTypes";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { filterMerchants } from "helpers/merchant";
import { GetMerchantsSlides } from "lib/api/cc/queries/merchants.gql";

import Slider from "components/ui/slider/Slider";

import SkeletonCard from "./card/skeleton/SkeletonCard";
import Card from "./card/Card";

import { MerchantSliderStyled, Title } from "./MerchantSlider.styled";

function getSlidesToShow(desktopView = false, tabletView = false) {
    if (desktopView) {
        return 3;
    }
    if (tabletView) {
        return 2;
    }

    return 1.5;
}

function renderCards(merchants) {
    if (!merchants || merchants.length <= 0) {
        return [];
    }

    return merchants.map(
        ({ title, slug, images, score, address, id, ...props }) => (
            <Card
                key={id}
                slug={slug}
                title={title}
                score={score}
                private={props?.private ?? false}
                image={
                    images?.[0] ?? {
                        url: "/images/placeholder.jpg",
                        alt: title
                    }
                }
                address={address}
            />
        )
    );
}

const generateSkeletonCards = limit =>
    Array(limit)
        .fill()
        .map((item, index) => (
            <SkeletonCard key={`MerchantSlider-skeleton-${index}`} />
        ));

function MerchantSlider({ title, merchants, filter, limit }) {
    const { mobileView, desktopView, tabletView } = useMediaQueryContext();
    const slidesToShow = getSlidesToShow(desktopView, tabletView);
    const [cards, setCards] = useState(generateSkeletonCards(limit));
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });
    const { loading } = useQuery(GetMerchantsSlides, {
        variables: {
            filter,
            pageSize: limit,
            sort: "PROMOTED"
        },
        onCompleted: ({ merchants }) => {
            const filteredMerchants = filterMerchants(merchants, true);

            if (filteredMerchants?.edges?.length > 0) {
                setCards(renderCards(filteredMerchants?.edges));
            }
        },
        skip:
            merchants?.length > 0 ||
            !inView ||
            !process.env.featureFlags.cmsData
    });

    useEffect(() => {
        if (merchants.length > 0) {
            setCards(renderCards(merchants));
        }
    }, [merchants]);

    return (
        <MerchantSliderStyled ref={ref} aria-busy={loading}>
            <Title>{title}</Title>
            {inView && (
                <Slider
                    navigationMobile={false}
                    items={cards}
                    slidesToShow={slidesToShow}
                    overflowVisible={mobileView}
                />
            )}
        </MerchantSliderStyled>
    );
}

MerchantSlider.propTypes = {
    title: PropTypes.string,
    merchants: PropTypes.arrayOf(MerchantSliderItemApiPropTypes),
    filter: PropTypes.object,
    limit: PropTypes.number
};

MerchantSlider.defaultProps = {
    title: "Meest populaire sauna’s & wellness centra",
    merchants: [],
    filter: {
        promoted: true
    },
    limit: 6
};

export default MerchantSlider;
